<template>
    <div class="main">
        <div class="cover fl">
            <div class="cover-top">
                <p>构建“一站式”数据管理利用平台</p>
                <ul class="list">
                    <li class="text fl">数据库管理</li><li class="line">|</li>
                    <li class="text fl">数据维护</li><li class="line">|</li>
                    <li class="text fl">数据查询利用</li>
                </ul>
            </div>
            <div class="cover-bottom">
                <p class="item first">推动数据管理录入及多人线上协作模式</p>
                <p class="item second">建立规范、安全、便捷的数据管理与利用机制</p>
                <p class="item third">构建并提供专业化数据服务平台</p>
            </div>
            <img class="img1" src="../../assets/home.png" />
            <img class="img2" src="../../assets/data.png" />
        </div>
        <div class="interactive fl">
            <img class="logo" src="../../assets/svg/soz_logo1.svg" alt="搜知" />
            <router-view></router-view>
            <div class="bottom">
                <span>Copyright©2019 北京搜知数据科技有限公司 版权所有</span><br />
                <span>京ICP备09019565号-1 京公网安备11010802021039</span>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle} from "lodash";

let throttledSetRem;
export default {
    name:'new_index',
    data(){
        return {

        }
    },
    methods:{
        setRem(){
            let vW = window.innerWidth; // 当前窗口的宽度
            const baseSize = 100; // 基准大小
            let basePc = baseSize / 1920;
            let rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
            document.documentElement.style.fontSize = rem + 'px';
        }
    },
    beforeMount(){
        this.setRem();
    },
    mounted(){
        throttledSetRem = throttle(this.setRem, 500);
        window.addEventListener('resize', throttledSetRem);
    },
    beforeDestroy(){
        window.removeEventListener('resize', throttledSetRem);
        document.documentElement.style.fontSize = null;
    }
    
}
</script>

<style lang="scss" scoped>
html,body{
    width: 100%;
    height: 100%;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.main{
    width:100%;
    height:100vh;
    min-width:13.66rem;
    min-height:7.68rem;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
    .cover{
        width: 50%;
        min-width:6.38rem;
        min-height:100vh;
        background-image:url("../../assets/cover.png");
        background-size:cover;
        position: relative;
        .cover-top{
            width:6.85rem;
            margin:0.95rem auto 0rem;
            text-align:center;
            color:#fff;
            p{
                letter-spacing:0.06rem;
                font-size:0.40rem;
                font-weight:500;
                margin-bottom:0rem;
            }
            .list{
                list-style: none;
                margin:0 auto;
                overflow:hidden;
                display:flex;
                justify-content:center;
                .line{
                    padding:0 0.2rem;
                }
                .text{
                    width: 86px;
                    font-size: 14px;
                }
            }
        }
        .cover-bottom{ 
            margin-top:5.8rem;
            .item{
                font-size:12px;
                margin-bottom:0.32rem;
                color:#fff;
            }
            .item:before{
                content:"";
                display:inline-block;
                width:0.1rem;
                height:0.1rem;
                border-radius:50%;
                background:#fff;
                margin-right:0.1rem;
                position: relative;
                top:-1px;
            }
            .first{
                margin-left:1rem;
            }
            .second{
                margin-left:2.5rem;
            }
            .third{
                margin-left:4rem;
            }
        }
        .img1{
            width:6.21rem;
            height:4.35rem;
            position:absolute;
            top:2.3rem;
            left:0.88rem;
        }
        .img2{
            width:5.26rem;
            height:3.16rem;
            position:absolute;
            top:4.2rem;
            right:0.88rem;
        }
    }
    .interactive{
        width: 50%;
        height: 100vh;
        min-width:6.38rem;
        position:relative;
        .logo{
            width:1.6rem;
            height:0.55rem;
            position:absolute;
            top:0.5rem;
            left:0.8rem;
        }
        .bottom{
            margin:0 auto;
            text-align:center;
            position:absolute;
            left:50%;
            bottom:0.2rem;
            transform: translateX(-50%);
            user-select: none;
            a{
                color:#387BF6;
                margin-bottom:16px;
            }
            span{
                color:#989CB2;
                font-size:12px;
            }
        }
    }
}

@media all and (max-height:763px) {
    .main .interactive .bottom{
        position: relative;
        margin-top:30px;
    }
}

@media all and (max-height:540px) {
    .main .interactive .bottom{
        position: relative;
        margin-top:30px;
    }
}
</style>