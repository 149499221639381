<template>
    <div class="login-box">
        <a-spin :spinning="spinning">
            <p class="title">登录搜知计蒙数据管理系统</p>
            <a-tabs v-model="activeKey" :tabBarStyle="tabBarstyle">
                <a-tab-pane class="tab-pane" key="1" tab="验证码登录">
                    <div class="form">
                        <div class="form-item">
                            <div class="label">登录账号</div>
                            <div class="input-wrap">
                                <a-input size="large" v-model="account" placeholder="请输入您的手机号码" />
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">输入验证码</div>
                            <a-input-group compact>
                                <a-input 
                                    size="large"
                                    v-model="verifyCode2" 
                                    placeholder="请输入您的手机号码"
                                    style="width: calc(100% - 120px);"
                                    ></a-input>
                                <a-button
                                    style="width:120px;height: 40px;background: #f7f7f7;"
                                    type="button"
                                    @click="getVerifyCode"
                                    :loading="loading"
                                    :disabled="disabled"
                                >{{ btnTxt }}</a-button>
                            </a-input-group>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane class="tab-pane" key="2" tab="密码登录">
                    <div class="form">
                        <div class="form-item">
                            <div class="label">登录账号</div>
                            <div class="input-wrap">
                                <a-input size="large" v-model="accountNum" placeholder="请输入您的手机号码"></a-input>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="label">
                                输入密码
                                <span style="float: right;color: #333;font-size: 14px;font-weight: normal;cursor: pointer;" @click="$router.push('resetPwd')">忘记密码</span>
                            </div>
                            <div class="input-wrap">
                                <a-input-password size="large" v-model="password" placeholder="请输入您的手机号码"></a-input-password>
                            </div>
                        </div>
                        <div class="form-item" v-show="needVerifyCode">
                            <div class="label">输入验证码</div>
                            <div class="input-wrap">
                                <img
                                    :src="imgUrl + '&random=' + randoms"
                                    alt="验证码"
                                    @click="randoms = Math.random()"
                                />
                                <a-input
                                    v-model="verifyCode"
                                    size="large"
                                    class="verifycode"
                                    style="border-radius: 4px 0px 0px 4px;"
                                />
                            </div>
                        </div>
                    </div>
                </a-tab-pane>   
            </a-tabs>
            <div class="btns">
                <a-button type="primary" size="large" style="font-size: 14px;" block @click="login">登录</a-button>
            </div>
            <div class="ip-register">
                <p class="register" @click="$router.push({path:'/register'})">申请试用</p>
            </div>
        </a-spin>
        <a-modal
            v-model="successVisible"
            wrapClassName="success-win"
            title=""
            :closable="false"
            centered
            :footer="null"
        >
            <div class="content">
            <a-icon type="check-circle" theme="filled" />
            <div class="tips1">恭喜您，登录成功！</div>
            <div class="btns">
                <a-button type="primary" @click="goVisit(1)">去数据查询</a-button>
                <a-button type="primary" @click="goVisit(2)">去数据录入</a-button>
            </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { isPhone, messageTips, setCookie ,getCookie,delCookie,stringToBase64} from "../../until/tools";
import config from "../../until/configs";
let timer;
    export default {
       name:'new_login',
       data(){
            return {
                spinning:false,
                activeKey:'1',
                account:null,
                accountNum:null,
                password:null,
                needVerifyCode:false,
                verifyCode:'',
                randoms:'',
                imgUrl:'',
                status:false,
                loading:false,
                verifyCode2:null,
                successVisible:false,
                rangeKey: 0,
                btnTxt: '发送验证码',
                disabled:false,
                tabBarstyle:{ margin: '0px'},
            }
       },

       watch: {
        account() {
                this.status = false;
                this.rangeKey = Math.random();
                this.verifyCode2 = "";
            },
        },

       methods:{
        onMpanelError(){},
        onMpanelSuccess() {
            if (!timer) {
                this.getVerifyCode();
            } else {
                this.status = true;
            }
        },

        async loginByCode(){
            if (!this.account) return messageTips("请输入手机账号", 2);
            if (!isPhone(this.account)) return messageTips("手机格式不正确", 2);
            if (!this.verifyCode2) return messageTips("请输入验证码", 2)
            let params = {
                account: this.account,
                verifyCode: this.verifyCode2,
                loginType: "MOBILE",
            };
            this.spinning = true;

            const res = await this.$http.login(params)
            if (res) {
                if (res.sid) {
                    if(res.canInput){
                        this.sid = res.sid;
                        setCookie('roleName',res.user.roleName,{time:1});
                        setCookie("bd_sid", res.sid, { time: 1 });
                        if (getCookie("loginTargetUrl")) {
                            let loginTargetUrl = getCookie("loginTargetUrl");
                            this.$router.push(loginTargetUrl);
                            delCookie("loginTargetUrl");
                        } else {
                            this.successVisible = true;
                        }
                    }else{
                        this.$router.push({ path: "/datas_data" });
                    }
                    this.spinning = false;
                } else {
                    this.spinning = false;
                    messageTips(res.message, 1);
                }
            }
        },

        timeInterval(seconds) {
            this.btnTxt = seconds + "s";
            this.disabled = true;
            timer = setInterval(() => {
                seconds -= 1;
                setCookie("verifyCutdown", seconds, { time: seconds }, true);
                this.btnTxt = seconds + "s";
                if (seconds < 0) {
                    delCookie("verifyCutdown");
                    this.btnTxt = "重新发送";
                    this.disabled = false
                    clearInterval(timer);
                }
            }, 1000);
        },
        async getVerifyCode() {
            this.status = true;
            this.loading = true
            const res = await this.$http.getSign({ account: this.account })
            if (!res.success) {
                messageTips(res.message, 1);
                this.loading = false
                return false;
            }
            
            const res2 = await this.$http.sendCode({ account: this.account, sign: res.sign})
            this.loading = false
            if (!res2.success) {
                messageTips(res2.message, 1);
                return  
            }
            if(res2.verifyCode){
                this.verifyCode2 = res.verifyCode;
            }
            this.timeInterval(59);
        },

        goVisit(arg) {
            if(arg == 1){
                this.$router.push({ path: "/datas_data" });
            }else{
                this.$router.push({ path: "/inputs_itemAllocation" });
            }
        },


        async continueLogin(params){
            this.spinning = true;
            const res = await this.$http.login(params)
            if (res) {
                if (res.sid) {
                    setCookie('roleName',res.user.roleName,{time:1});
                    setCookie("bd_sid", res.sid, { time: 1 });
                    this.sid = res.sid;
                    if (res.canInput === 1) {
                        // this.successVisible = true;
                        let loginTargetUrl=getCookie('loginTargetUrl')
                        if(loginTargetUrl){
                            this.$router.push({path:loginTargetUrl})
                            delCookie("loginTargetUrl");
                        }else{
                            this.successVisible = true;
                        }
                        this.spinning = false;
                        setCookie("canInput", res.canInput, { time: 1 });
                    } else {
                        this.$router.push({ path: "/datas_data" });
                    }
                } else {
                    this.spinning = false;
                    messageTips(res.message, 1);
                }
            }
        },

        async loginByPassword(){
            if(!this.accountNum) return messageTips('请输入登录账号',2)
            if(!isPhone(this.accountNum)) return messageTips("手机号码格式不正确",2)
            if(!this.password) return messageTips('请输入登录密码',2)

            const params = {
                account:this.accountNum,
                password:stringToBase64(this.password),
                loginType:'pwd'
            }
            this.spinning = true;

            const res = await this.$http.isNeedVerifyCode({account: params.account})
            if(res.needVerifyCode){
                this.needVerifyCode = true
                if(this.verifyCode === ""){
                    messageTips("请输入验证码", 2);
                    const res1 = await this.$http.getSign({ account: this.accountNum })
                    if (res1) {
                        if (res1.sign) {
                            this.imgUrl = config.imgCode +"?account=" +this.accountNum +"&sign" + res1.sign;
                        }
                    }
                    this.spinning = false;
                    return false;
                }else{
                    params.imageCode = this.verifyCode;
                    this.continueLogin(params);
                }
            }else{
                this.continueLogin(params);
            }
        },
        /**
         * activeKey:1 验证码登录
         * activeKey:2 密码登录
         */
        login(){
            this.activeKey == 1 ? this.loginByCode() : this.loginByPassword()
        }


       },
       mounted(){
        let verifyCutdown = getCookie("verifyCutdown");
        if (verifyCutdown) {
            let seconds = parseInt(verifyCutdown);
            this.timeInterval(seconds);
        }
       },
        
    }
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";
.login-box{
    margin:2rem auto 0px;
    background: #fff;
    position: relative;
    z-index: 99;
    width:4.5rem;
    .title{
        margin-bottom:0rem;
        font-size:0.28rem;
        font-weight:500;
        color:#333;
    }
    .ant-tabs{
        color:#333;
        /deep/ .ant-tabs-nav .ant-tabs-tab{
            padding:19px 0px
        }
    }
    .tab-pane{
        .form{
            .form-item{
                .label{
                    margin-top:0.15rem;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    color:#333;
                }
                // .login-btn{
                //     margin-top:0.28rem;
                // }
                .input-wrap{
                    img{
                        display: inline-block;
                        width: 100px;
                        height: 40px;
                        float: right;
                    }
                    .verifycode{
                        width: calc( 100% - 100px);
                    }
                }
            }
        }
    }
    .btns{
        margin-top:0.3rem;
    }
    .ip-register{
        margin-top:0.15rem;
        overflow: hidden;
        .register{
            float:right;
            font-size: 14px;
            cursor: pointer;
            color:$primary-color;
        }
    }
}
.success-win {
    .content {
        text-align: center;
        .anticon-check-circle {
            font-size: 40px;
            color:$primary-color;
        }
        .tips1 {
            font-size: 28px;
            font-weight: bold;
            color:$primary-color;
            padding:10px 0;
        }
        .tips2 {
            padding:10px 0;
            .ant-statistic {
                display: inline-block;
            }
        }
        .btns {
            padding-top:10px;
            .ant-btn-primary {
                background:#2c3032;
                border-color: #2c3032;
                margin:0 16px;
            }
        }
    }
}
.ant-input-lg{
    font-size: 14px;
    height:40px;
}
.ant-input-password{
    /deep/.ant-input-lg{
        font-size: 14px;
    }
}
</style>